@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap&family=Inter:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Inter", sans-serif;
}

a:hover {
	color: initial;
}

input,
textarea {
	font-size: 14px;
}

.ant-popover-arrow {
	display: none;
}

.drawer_project-create {
	.ant-drawer-content-wrapper {
		box-shadow: none;

		.ant-drawer-content {
			height: 96%;
			border-radius: 10px;
			overflow: hidden;
			transform: translate(-18px, 18px);
		}
	}
}

.v-menu_extra {
	.ant-popover-inner {
		overflow: hidden;
		border-radius: 7px;

		.ant-popover-inner-content {
			padding: 10px 8px;

			ul {
				border: none;

				button {
					box-shadow: none !important;
					padding-left: 12px;
					padding-right: 12px;
				}
			}
		}
	}
}

.v-menu_notification {
	.ant-popover-content {
		transform: translateX(6px);

		.ant-popover-inner {
			overflow: hidden;
			border-radius: 7px;

			.ant-popover-inner-content {
				padding: 14px;
			}
		}
	}
}

.sidebar-menu-item.active {
	background-color: rgb(243 244 246);
}

.project-menu-item.active {
	background-color: #1c64f2;
	color: white;
}

.crisp-client #crisp-chatbox {
	z-index: 999 !important;
}

.custom-modal {
	.ant-modal-content {
		border-radius: 8px;
		overflow: hidden;
	}
}
